<template>
  <div class="container">
    <div
      class="d-flex justify-content-between mb-3 align-items-center flex-wrap"
    >
      <h1 class="m-0 mb-2">Intake Categories</h1>
      <router-link
        to="/admin/intake-categories/create"
        class="btn btn-outline-blue py-2"
      >
        <icon type="plus" /> Add New Category
      </router-link>
    </div>

    <div class="card theme-card mobile-tablet-no-card border-lg-0">
      <alert v-if="loading" class="light-shadow m-4" />
      <alert class="m-4" :hideLoader="true" v-else-if="categories.length == 0">
        No Categories To List, Create New One Using Add Button.
      </alert>
      <div
        class="
          card
          mb-0
          theme-card
          mobile-tablet-no-card
          p-2 p-md-0
          table-container
          border-lg-0
        "
        v-else
      >
        <table class="table table-striped mb-0 responsive-mobile-tablet-table">
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="category in categories" :key="category.id">
              <td>
                <b class="mobile-tablet-only mr-2">ID:</b>{{ category.type_id }}
              </td>
              <td>
                <b class="mobile-tablet-only mr-2">Name:</b>{{ category.name }}
              </td>
              <td>
                <b class="mobile-tablet-only mr-2">Status:</b
                ><span
                  :class="
                    category.status == 'ACTIVE' ? 'text-success' : 'text-danger'
                  "
                  >{{ category.status }}</span
                >
              </td>
              <td class="td-actions">
                <div
                  class="d-flex justify-content-center justify-content-lg-end"
                >
                  <router-link
                    :to="`/admin/intake-categories/${category.id}`"
                    class="btn btn-sm btn-black"
                  >
                    Edit
                    <icon type="edit" />
                  </router-link>
                  <button
                    class="btn btn-danger btn-sm ml-3"
                    @click="deleteCategoryHandler(category)"
                  >
                    Delete
                    <i
                      class="fas fa-spin fa-circle-notch"
                      v-if="category.deleting"
                    ></i>
                    <icon v-else type="trash-alt" />
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import Swal from "sweetalert2";
import { mapActions, mapState } from "vuex";
export default {
  computed: {
    ...mapState({
      categories: (state) => state.intakeForms.categories.data,
      loading: (state) => state.intakeForms.categories.isLoading,
    }),
  },
  methods: {
    ...mapActions({
      getIntakeCategories: "intakeForms/getCategories",
      deleteCategory: "intakeForms/deleteCategory",
    }),
    deleteCategoryHandler(category) {
      const vm = this;
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-black ml-4",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: false,
      });
      swalWithBootstrapButtons
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, delete it!",
          cancelButtonText: "No, cancel!",
          reverseButtons: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            vm.deleteCategory(category.id).then((success) => {
              if (success) {
                vm.getIntakeCategories();
              }
            });
          }
        });
    },
  },
};
</script>

<style scoped lang="scss">
.form-group {
  position: relative;
}
.cancel-icon,
.search-icon {
  z-index: 10;
  position: absolute;
  right: 40px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  color: #dc143c;
  cursor: pointer;
}
.search-icon {
  right: 15px;
}
</style>
